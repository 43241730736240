<template>
  <div class="date" :class="[{ inline: inline, icon: icon }, size]" v-if="dateTime">
    <icon
      v-if="icon"
      data="@icon/calendar.svg"
      color="var(--primary-color)"
      width="1.25em"
      height="1.25em"
      class="p-mr-2"
    />
    <strong v-if="date" :style="{ color: dateColor }">{{ dateTime.format(format) }}</strong>
    <span v-if="hour" :style="{ color: hourColor }">{{ dateTime.format(hourFormat) }}</span>
    <small v-if="fromNow">{{ dateTime.fromNow() }}</small>
  </div>
</template>

<script>
import dateTimeMixins from "@/mixins/dateTime.js";

export default {
  name: "Date",
  mixins: [dateTimeMixins],
  props: {
    hour: Boolean,
    hourColor: String,
    date: {
      type: Boolean,
      default: true,
    },
    dateColor: String,
    format: {
      type: String,
      default: "D MMM YYYY",
    },
    inline: {
      type: Boolean,
      default: true,
    },
    icon: Boolean,
    size: {
      type: String,
      default: "medium",
    },
    fromNow: Boolean,
    isUtc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const originalDateTime = this.$slots.default()[0].children;
    const normalizedDate = this.normalizeUtcDate(originalDateTime);
    const dateTime = this.isUtc ? this.$day.utc(normalizedDate).local() : this.$day(normalizedDate);

    return {
      dateTime: normalizedDate ? dateTime : undefined,
      hourFormat: "HH:mm",
    };
  },
};
</script>

<style scoped lang="scss">
.date {
  &.inline {
    white-space: nowrap;

    &.icon {
      display: inline-flex;
      align-items: center;
      background: rgba(132, 118, 88, 0.05);
      padding: 0.5rem 0.75rem 0.5rem 0.66667rem;
      border-radius: var(--border-radius-sm);
    }

    strong {
      display: inline;
      margin-right: 0.25em;
    }
  }
  strong {
    display: block;
    font-size: 1em;
    font-weight: 500;
  }
  span {
    font-size: 1em;
    font-weight: 500;
    color: var(--secondary-color-text-neutral-darker);
  }
  small {
    display: block;
  }
  &.small {
    font-size: 0.9rem;
  }
  &.large {
    font-size: 1.5rem;
  }
}
</style>
